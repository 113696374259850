import React, { useEffect } from "react"
import { navigate } from "gatsby"

const QuantifyingImpact = () => {
  // This is a redirect route to quantifying-impact page
  useEffect(() => {
    navigate("/quantifying-impact")
  }, [])

  return <div />
}

export default QuantifyingImpact
